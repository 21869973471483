var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "flex flex-col items-center justify-center bg-gray-200" },
    [
      _c("div", { staticClass: "flex flex-col items-center justify-center" }),
      _vm._v(" Message: " + _vm._s(_vm.message) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }