
import { ApiClient } from '@/api/ApiClient';
import { Component, Inject, Vue } from 'vue-property-decorator';

@Component<APITestPage>({})
export default class APITestPage extends Vue {
  @Inject()
  apiClient!: ApiClient;

  message = 'Empty';

  async mounted() {
    const response = await this.apiClient.defaultClient.getHello();
    console.log('response: ', response);
    this.message = response.data;
  }
}
